import { render, staticRenderFns } from "./capitalBlindModule.vue?vue&type=template&id=359a72b2&scoped=true&"
import script from "./capitalBlindModule.vue?vue&type=script&lang=ts&"
export * from "./capitalBlindModule.vue?vue&type=script&lang=ts&"
import style0 from "./capitalBlindModule.vue?vue&type=style&index=0&id=359a72b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "359a72b2",
  null
  
)

export default component.exports