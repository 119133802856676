var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "capital-module"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('cap-blind-filters')], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4 offset-8"
  }, [_c('div', {
    staticClass: "le-picker"
  }, [_c('p', {
    staticClass: "le-picker__label label"
  }, [_vm._v("FUND")]), _c('sygni-select', {
    attrs: {
      "options": []
    }
  })], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('cap-blind-table', {
    ref: "capBlindTable",
    attrs: {
      "show-page-number-options": false,
      "tableData": _vm.shareHoldersSeriesStats,
      "setTableBusyMutation": "capital/setCapitalTableBusy",
      "busy": _vm.isBusy
    }
  })], 1)])]), _c('sygni-access-modal')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }