































import Vue from "vue";
import Component from "vue-class-component";
import SygniCard from "@/components/layout/SygniCard.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRadio from "@/components/inputs/SygniRadio.vue";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import { Prop } from "vue-property-decorator";

interface FiltersObject {
  type: string,
  sortBy: string,
  date: string
}

@Component({
  components: { SygniCard, SygniSelect, SygniRadio, SygniDatePicker },
})

export default class CapFilters extends Vue {
  @Prop() companies: any;

  date: string = '';

  filters: FiltersObject = {
    type: 'ALL',
    sortBy: 'nominal-value',
    date: ''
  }
}
