




























import Vue from 'vue'
import Component from 'vue-class-component'
import { mapState } from 'vuex';
import CapBlindTable from '../components/CapBlindTable.vue'
import CapBlindFilters from '../components/CapBlindFilters.vue'
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniAccessModal from "@/components/layout/SygniAccessModal.vue";

@Component({
  components: { CapBlindTable, CapBlindFilters, SygniSelect, SygniAccessModal },
  computed: mapState('capital', {
    capitalTable: (state: any) => state.capitalTable,
    isBusy: (state: any) => state.capitalTableIsBusy,
    perPage: (state: any) => state.capitalTable.perPage,
  })
})
export default class capitalModule extends Vue {
  filtersQueryString: string = '';
  capitalTable!: any;
  isBusy!: boolean;
  perPage!: number;

  get shareHoldersSeriesStats() {
    return this.capitalTable?.shareHoldersSeriesStats;
  }

  get accessModules() {
    return this.$store.getters['genprox/getAccessModules'];
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  mounted() {
    // this.$store.dispatch('capital/getCapitalByNominalValue');
  }
}
