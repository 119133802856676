
















































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BTable, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/fundraising/components/InvestorsInnerTable.vue";
import { required } from 'vuelidate/lib/validators'
import CapInnerTable from './CapInnerTable.vue';
import breakpoints from '@/plugins/breakpoints';

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, BTable, CapInnerTable },
})
export default class CapBlindTable extends SygniTable<any> {
  breakpoints = breakpoints;
  filtersQuery: string = 'by-nominal';
  refresh: any = null;
  data: any = {
    "totalSum": 174071,
    "capTableType": "nominal",
    "generalPartnersStats": [],
    "optionsStats": {
      "founders": {
        "numberOfShares": 0,
        "value": 0
      },
      "investors": {
        "numberOfShares": 0,
        "value": 0
      },
      "options": {
        "numberOfShares": 0,
        "value": 0
      },
      "totals": {
        "numberOfShares": 0,
        "value": 0
      },
      "items": {
        "b0a2e490-6c4e-4cf1-b118-8b63618728a2": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 1"
        },
        "ddca756d-be60-4178-aa83-9186ab65d703": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 2"
        }
      }
    },
    "shareHoldersTotals": {
      "founders": {
        "numberOfShares": 0,
        "value": 0
      },
      "investors": {
        "numberOfShares": 0,
        "value": 0
      },
      "options": {
        "numberOfShares": 0,
        "value": 0
      },
      "totals": {
        "numberOfShares": 0,
        "value": 0
      }
    },
    "shareHoldersSeriesStats": {
      "37c65c57-1975-4a07-8fac-8adebc2a1795": {
        "founders": {
          "numberOfShares": 0,
          "value": 0
        },
        "investors": {
          "numberOfShares": 0,
          "value": 0
        },
        "options": {
          "numberOfShares": 0,
          "value": 0
        },
        "totals": {
          "numberOfShares": 0,
          "value": 0
        },
        "items": null,
        "name": "Test 3"
      },
      "63607dc9-02f8-4fc0-8a04-36dc926460b8": {
        "founders": {
          "numberOfShares": 0,
          "value": 0
        },
        "investors": {
          "numberOfShares": 0,
          "value": 0
        },
        "options": {
          "numberOfShares": 0,
          "value": 0
        },
        "totals": {
          "numberOfShares": 0,
          "value": 0
        },
        "items": null,
        "name": "Test 4"
      },
      "a34d0394-0b3f-43ab-8ae7-c6a9b853d69f": {
        "founders": {
          "numberOfShares": 0,
          "value": 0
        },
        "investors": {
          "numberOfShares": 0,
          "value": 0
        },
        "options": {
          "numberOfShares": 0,
          "value": 0
        },
        "totals": {
          "numberOfShares": 0,
          "value": 0
        },
        "items": null,
        "name": "Test 5"
      },
      "ed9a9d7d-5806-43e0-a3dc-a173705cf67a": {
        "founders": {
          "numberOfShares": 0,
          "value": 0
        },
        "investors": {
          "numberOfShares": 0,
          "value": 0
        },
        "options": {
          "numberOfShares": 0,
          "value": 0
        },
        "totals": {
          "numberOfShares": 0,
          "value": 0
        },
        "items": null,
        "name": "Test 6"
      },
    },
    "totals": {
      "founders": {
        "numberOfShares": 0,
        "value": 0
      },
      "investors": {
        "numberOfShares": 0,
        "value": 0
      },
      "options": {
        "numberOfShares": 0,
        "value": 0
      },
      "totals": {
        "numberOfShares": 0,
        "value": 0
      },
      "items": {
        "b0a2e490-6c4e-4cf1-b118-8b63618728a2": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 1"
        },
        "ddca756d-be60-4178-aa83-9186ab65d703": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 2"
        },
        "37c65c57-1975-4a07-8fac-8adebc2a1795": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 3"
        },
        "63607dc9-02f8-4fc0-8a04-36dc926460b8": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 4"
        },
        "a34d0394-0b3f-43ab-8ae7-c6a9b853d69f": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 5"
        },
        "ed9a9d7d-5806-43e0-a3dc-a173705cf67a": {
          "founders": {
            "numberOfShares": 0,
            "value": 0
          },
          "investors": {
            "numberOfShares": 0,
            "value": 0
          },
          "options": {
            "numberOfShares": 0,
            "value": 0
          },
          "totals": {
            "numberOfShares": 0,
            "value": 0
          },
          "name": "Test 6"
        },
      }
    }
  };

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'name', sortable: false, label: 'Shareholders'},
    { key: 'founders.numberOfShares', sortable: false, label: '# OF SHARES'},
    { key: 'founders.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'investors.numberOfShares', sortable: false, borderless: false , label: '# OF SHARES' },
    { key: 'investors.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'options.numberOfShares', class: 'hide', sortable: false, label: '# OF SHARES' },
    { key: 'options.value', class: 'hide', sortable: false, label: 'VALUE' },
    { key: 'totals.numberOfShares', class: 'hide', sortable: false, label: '# OF SHARES' },
    { key: 'totals.value', sortable: false, label: 'VALUE' },
    { key: 'totalPercentage', sortable: false, label: 'PERCENT STRUCTURE' },
  ];

  get tableItems() {
    return Object.values(this.data.shareHoldersSeriesStats);
  }

  get totals() {
    return this.data.totals;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPortfolioCompany() {
    return this.$route.path.includes('/company');
  }

  get viewedFundId() {
    return this.$store.getters['auth/getViewedFundId'];
  }

  get viewedCompanyId() {
    return this.$store.getters['auth/getViewedCompanyId'];
  }

  get viewedId() {
    return this.isPortfolioCompany ? this.viewedCompanyId : this.viewedFundId;
  }

  getTotalPercentage(amount: number) {
    const total = this.totals.totals.value ? this.totals.totals.value : 0;
    amount = Number(amount.toFixed(2)) * 100 / Number(total.toFixed(2));
    amount = Math.round((amount + Number.EPSILON) * 100) / 100;

    return `${this.$options.filters.numberFormat(amount, 2)}%`;
  }

  async onFiltersChange(queryString: string) {
    this.filtersQuery = queryString;
    await this.getItems();
  }

  validations () {
    return  {
      selectedBankAccount: { required },
    }
  }

  async beforeMount() {
    await this.getItems();
    // this.onMounted();
    // this.setBorderlessTds();
  }
}

